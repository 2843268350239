import React, { useState, useCallback, useRef } from "react";
import Flag from "../flag";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import "./style.scss";

const LanguageSelect = () => {
  const options = useRef([
    { id: "pl-PL", label: "Polski", shortId: "pl" },
    { id: "de-DE", label: "Deutsch", shortId: "de" },
  ]);
  const ref = useRef(null);
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(
    options.current.filter((option) => option.id == i18n.language)?.[0] ?? {}
  );

  const select = useCallback(
    (option) => {
      ReactGA.event({
        category: "Button",
        action: `Change language from ${selectedOption.id} to ${option.id}`,
      });
      setSelectedOption(option);
      i18n.changeLanguage(option.id);
    },
    [setSelectedOption, i18n]
  );

  return (
    <div className="dtm-language-select" ref={ref}>
      {options.current.map((option, index) => {
        return (
          <React.Fragment key={index}>
            <a
              tabIndex="0"
              href="#"
              aria-label={`Select Language ${option.label}`}
              className={`dtm-language-select__option ${
                selectedOption.id == option.id
                  ? "dtm-language-select__option--selected"
                  : ""
              }`}
              key={index}
              onClick={() => {
                select(option);
              }}
            >
              <Flag country={option.id} />
              <span>{option.shortId.toUpperCase()}</span>
            </a>
            {index != options.current.length - 1 && <span>/</span>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default LanguageSelect;
