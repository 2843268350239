import { Button, Text } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ChevronsUp, Cookie } from "tabler-icons-react";
import { ConsentContext } from "../consent_box";
import "./style.scss";

const Footer = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const { setIsVisible } = useContext(ConsentContext);

  return (
    <div className="dtm-footer">
      <Button
        leftIcon={<ChevronsUp />}
        variant="default"
        radius="xl"
        size="xs"
        className="m-b-l"
        onClick={(_) => {
          scrollTo({ y: 0, x: 0 });
        }}
      >
        Zurück nach oben
      </Button>
      <Text size="lg">Dorota Tworkowski</Text>
      <Text>Versicherungen &amp; Buchhaltung</Text>
      <div className="dtm-footer__sitemap">
        <Link to="/">Startseite</Link>
        <span>&bull;</span>
        <Link to="/kontakt">Kontakt</Link>
        <span>&bull;</span>
        <Link to="/impressum">Impressum</Link>
        <span>&bull;</span>
        <Link to="/datenschutz">Datenschutz</Link>
      </div>
      <Button
        variant="subtle"
        color="dark"
        uppercase
        leftIcon={<Cookie size={20} />}
        onClick={() => {
          setIsVisible(true);
        }}
      >
        Cookie Settings
      </Button>
      {`© ${new Date().getFullYear()} Oliver Tworkowski`}
    </div>
  );
};

export default Footer;
