import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { Check, CircleX, Cookie as CookieIcon } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { ActionIcon, Button } from "@mantine/core";
import ENVIRONMENT from "../../utils/environment";
import ReactGA from "react-ga";
import "./style.scss";

const CONSENT_COOKIE_ID = "CONSENT_COOKIE_1337";
const ConsentContext = createContext();

const ConsentContextProvider = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <ConsentContext.Provider
      value={{
        isVisible,
        setIsVisible,
      }}
    >
      {props.children}
    </ConsentContext.Provider>
  );
};

const ConsentBox = (_props) => {
  const location = useLocation();
  const cookies = useMemo(() => new Cookies(), []);
  const { setIsVisible, isVisible } = useContext(ConsentContext);
  /* const [hasConsent, setHasConsent] = useState(); */
  const [cookiesToRemove, _] = useState(["_gid", "_ga", "_gat"]);
  const [isGaInitialized, setIsGaInitialized] = useState(false);
  const { t } = useTranslation();

  const handleConsentChange = useCallback(
    (_hasConsent) => {
      if (_hasConsent === null || _hasConsent === undefined) return;

      // set consentcookie and disable visiblity
      if (_hasConsent === true || _hasConsent === false) {
        cookies.set(CONSENT_COOKIE_ID, _hasConsent, {
          path: "/",
          maxAge: 2419200, // 28 days
        });
      }
      setIsVisible(false);

      if (_hasConsent) {
        // has consent
        ReactGA.initialize(ENVIRONMENT.GOOGLE_ANALYTICS_TRACKER_ID);
        setIsGaInitialized(true);
      } else {
        // doesnt have consent
        /* const allCurrentCookies = cookies.getAll();
        const hasCookiesToRemove = Object.keys(allCurrentCookies ?? {}).some(
          (e) => cookiesToRemove.includes(e)
        );
        console.log(Object.keys(allCurrentCookies ?? {}), hasCookiesToRemove); */
        cookiesToRemove.forEach((e) => {
          cookies.remove(e);
        });
        setIsGaInitialized(false);
        /* if (hasCookiesToRemove) {
          window.location.reload();
        } */
      }
    },
    [cookies, cookiesToRemove, setIsVisible]
  );

  // initial determination from cookie
  useLayoutEffect(() => {
    const cookie = cookies.get(CONSENT_COOKIE_ID);
    var action = cookie ? cookie.toString() === "true" : null;
    setIsVisible(action == null);
    handleConsentChange(action);
  }, [cookies, handleConsentChange, setIsVisible]);

  // set GA view
  useEffect(() => {
    if (!isGaInitialized) return;
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  }, [location, isGaInitialized]);

  return isVisible ? (
    <div className={"dtm-consent-box"}>
      <div>
        <div className={"dtm-consent-box__header"}>
          <CookieIcon className={"dtm-consent-box__header__icon"} />
          <h2>{t("cookie_consent_title")}</h2>
        </div>
        {t("cookie_consent_message")}
      </div>
      <div className={"dtm-consent-box__btn-container m-t-l"}>
        <Button
          radius="xl"
          variant="filled"
          color="violet"
          size="sm"
          leftIcon={<Check size={14} />}
          onClick={() => handleConsentChange(true)}
        >
          {t("cookie_consent_agree")}
        </Button>
        <Button
          radius="xl"
          variant="light"
          size="sm"
          onClick={() => handleConsentChange(false)}
        >
          {t("cookie_consent_disagree")}
        </Button>
      </div>
      <ActionIcon
        onClick={() => setIsVisible(false)}
        className="dtm-consent-box__close-btn"
        variant="transparent"
      >
        <CircleX></CircleX>
      </ActionIcon>
      <div className={"dtm-consent-box__link-container m-t-l"}>
        {t("cookie_consent_more_info")}
        <Link to="/datenschutz">{" Datenschutz"}</Link>
      </div>
    </div>
  ) : null;
};

export { ConsentBox, ConsentContext, ConsentContextProvider };
