import React from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";
import {
  ConsentBox,
  ConsentContextProvider,
} from "../../components/consent_box";
import Footer from "../../components/footer";
import Header from "../../components/header";
import PageRouter, { RuntimeContextProvider } from "../../components/page_router";
import "./style.scss";

const App = () => {
  return (
    <div className="dtm-app">
      <Router>
        <ConsentContextProvider>
          <RuntimeContextProvider>
            <ConsentBox />
            <Header
              links={[
                { to: "/", label: "Startseite" },
                { to: "/kontakt", label: "Kontakt" },
                { to: "/impressum", label: "Impressum" },
              ]}
            />
            <main className="dtm-main">
              <PageRouter />
              <Footer />
            </main>
          </RuntimeContextProvider>
        </ConsentContextProvider>
      </Router>
    </div>
  );
};

export default App;
