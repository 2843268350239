import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/app";
import "./i18n";
import { init } from "@emailjs/browser";
import "./style.scss";
import ENVIRONMENT from "./utils/environment";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

init(ENVIRONMENT.EMAILJS_USER_ID);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /* Disabled refetching data if parameters didn't change */
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

const root = document.getElementById("root");
ReactDOM.render(
  <MantineProvider>
    <NotificationsProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </NotificationsProvider>
  </MantineProvider>,
  root
);
