import React from "react";
//import LogoImage from "../../assets/images/logo.png";
import LogoImg from "../../assets/images/logo_alt_black.png";
import "./style.scss";

const Logo = (props) => {
  const { customClass } = props;
  return <div className={`dtm-logo ${customClass ? customClass : ""}`}>
    <img src={LogoImg} className="dtm-logo__image" height={40} />
    {/* DT */}
  </div>;
};

export default Logo;
