import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Menu2, StepOut } from "tabler-icons-react";
import { ActionIcon, Burger } from "@mantine/core";
import "./style.scss";
import Logo from "../logo";
import LanguageSelect from "../language_select";
import LanguageSelectBox from "../language_select_box";

const Header = (props) => {
  const { links } = props;
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  return (
    <div className="dtm-header">
      <div className="dtm-header__content">
        <div className="dtm-header__left">
          <div
            className="dtm-header__menu"
            onClick={() => {
              setIsMobileNavOpen((old) => !old);
            }}
          >
            {isMobileNavOpen ? (
              <ActionIcon size="xl">
                <StepOut />
              </ActionIcon>
            ) : (
              <ActionIcon size="xl">
                <Menu2 />
              </ActionIcon>
            )}
          </div>
          {/* <Burger
              opened={isMobileNavOpen}
              onClick={() => setIsMobileNavOpen((old) => !old)}
              title="Menu"
            /> */}
          <div className="dtm-header__logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
        <div
          className={`dtm-header__nav ${
            isMobileNavOpen ? "dtm-header__nav--open" : ""
          }`}
          onClick={() => {
            setIsMobileNavOpen(false);
          }}
        >
          {links.map((link, index) => {
            return (
              <NavLink
                key={index}
                exact
                className="dtm-header__nav__item"
                activeClassName="dtm-header__nav__item--selected"
                data-text={link.label}
                to={link.to}
              >
                {link.label}
              </NavLink>
            );
          })}
        </div>
        <div className="dtm-header__right">
          {/* <LanguageSelect /> */}
          <LanguageSelectBox />
        </div>
      </div>
    </div>
  );
};

export default Header;
