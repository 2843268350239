import React, { useState, useCallback, useRef, useEffect } from "react";
import Flag from "../flag";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { ChevronDown, ChevronUp, Language } from "tabler-icons-react";
import { ActionIcon } from "@mantine/core";

const LanguageSelectBox = () => {
  const options = useRef([
    { id: "pl-PL", label: "Polski", shortId: "pl" },
    { id: "de-DE", label: "Deutsch", shortId: "de" },
  ]);
  const ref = useRef(null);
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(
    options.current.filter((option) => option.id == i18n.language)?.[0] ?? null
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const select = useCallback(
    (option) => {
      setSelectedOption(option);
      setIsDropdownOpen(false);
      i18n.changeLanguage(option.id);
    },
    [setSelectedOption, setIsDropdownOpen, i18n]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsDropdownOpen]);

  return (
    <div className="dtm-language-select-box" ref={ref}>
      {/* <a
        href="#"
        className="dtm-language-select-box__preview"
        tabIndex="0"
        aria-label="Language Select"
        onClick={() => {
          setIsDropdownOpen((old) => !old);
        }}
      >
        <Flag country={selectedOption?.id ?? ""} />
        {isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
      </a> */}
      <ActionIcon
        size="xl"
        onClick={() => {
          setIsDropdownOpen((old) => !old);
        }}
      >
        <Language />
        {selectedOption !== null && <Flag country={selectedOption?.id ?? ""} />}
      </ActionIcon>
      {isDropdownOpen && (
        <div className="dtm-language-select-box__dropdown">
          {options.current.map((option, index) => {
            return (
              <a
                tabIndex="0"
                href="#"
                aria-label={`Select Language ${option.label}`}
                className={`dtm-language-select-box__dropdown__option ${
                  option.id === selectedOption?.id
                    ? "dtm-language-select-box__dropdown__option--selected"
                    : ""
                }`}
                key={index}
                onClick={() => {
                  select(option);
                }}
              >
                <Flag country={option.id} />
                <span>{option.label}</span>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSelectBox;
